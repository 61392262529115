(function() {
    $('.component-mobilemenu .toggle-nav').click(function() {
        $('body').addClass('mobilemenu-on');
    });
    $('.component-mobilemenu .header .close').click(function() {
        $('body').removeClass('mobilemenu-on');
        if(openSubMenuLi) {
            openSubMenuLi.removeClass('sub-menu-on');
            openSubMenuLi = null;
        }
    });
    var openSubMenuLi = null;
    $('.component-mobilemenu .menu-arrow').click(function() {
        var li = $(this).parent();
        li.toggleClass('sub-menu-on');
        if(li.hasClass('sub-menu-on')) {
            if(openSubMenuLi) {
                openSubMenuLi.removeClass('sub-menu-on');
            }
            openSubMenuLi = li;
        }
        else {
            openSubMenuLi = null;
        }
    });

    /**
     *  Calculate sub menu heights.
     */
    var styles = {};
    var calculateSubMenuHeights = function() {
        $('.component-mobilemenu .sub-menu').each(function() {
            var li = $(this).parent();
            var id = li.attr('id');
            // Create style tag.
            if(styles[id] === undefined) {
                styles[id] = $('<style></style>');
                $('head').append(styles[id]);
            }
            // Calculate menu height and save style.
            $t = $(this);
            $t.css({
                "max-height": "none",
                "visibility": "hidden",
                "transition": "none",
                "-webkit-transition": "none",
                "-moz-transition": "none",
                "-o-transition": "none",
                "-ms-transition": "none"
            });
            var h = $t.height();
            $t.attr('style', '');
            styles[id].text('.component-mobilemenu nav li#'+id+'.sub-menu-on .sub-menu {max-height: '+h+'px;}');
        });
    };
    calculateSubMenuHeights();
    $(window).resize(function() {
        calculateSubMenuHeights();
    });

})();
